<template>
  <div
    class="flex"
    :class="containerClass"
  >
    <base-input
      v-model="inputValue"
      v-bind="{...attrs, ...inputProps}"
      input-container-class="h-full"
      input-inner-container-class="h-full"
      @enterPressed="handleClick"
    >
      <template
        v-for="(_, name) in slots"
        #[name]
        :key="'inline-button-input-slot' + name"
      >
        <slot :name="name" />
      </template>
    </base-input>
    <base-button
      class="inline-input-button"
      v-bind="btnProps"
      @click="handleClick"
    >
      <template
        v-for="(_, name) in slots"
        #[name]
        :key="'inline-base-button-slot' + name"
      >
        <slot :name="name" />
      </template>
    </base-button>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref, watch } from 'vue';

export default {
    name: 'InlineButtonInput',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue'))
    },

    inheritAttrs: false,

    props: {
        modelValue: {
            type: [String, Number],
            default: '',
            description: 'bound value to base-input'
        },
        inputProps: {
            type: Object,
            default: () => {},
            description: 'Props object for base-input(check base-input component for supported props)'
        },
        btnProps: {
            type: Object,
            default: () => {},
            description: 'Props object for base-button(check base-button component for supported props)'
        },
        containerClass: {
            type: [Object, Array, String],
            default: '',
            description: 'classes applied to root element'
        }
    },

    emits: [
        'click'
    ],

    setup (props, { attrs, emit, slots }) {
        // input logic
        const inputValue = ref('');
        onMounted(() => {
            inputValue.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            () => {
                if (inputValue.value !== props.modelValue) {
                    inputValue.value = props.modelValue;
                }
            }
        );

        // button logic
        const handleClick = (evt) => {
            emit('click', evt);
        };

        return {
            inputValue,
            handleClick,
            attrs,
            slots
        };
    }
};
</script>

<style scoped>
@layer components {
  .inline-input-button {
    @apply flex items-center rounded-l-none;
  }
}
</style>
